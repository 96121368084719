<template>
    <div class="fl_c al_c mar_bottom">
        <div class="fl_r ju_b contain" v-if="!$common.mobile()">
            <div class="content">
                <div v-if="articleDetail" class="pad_around">
                    <div class="fl_c al_c">
                        <div class="contentTitle">
                            {{articleDetail.title}}
                        </div>
                        <div class="contentTime">
                            {{articleDetail.showTime}}
                        </div>
                        <img class="contentImg" v-if="articleDetail.titleImg" :src="articleDetail.titleImg" alt="">
                        <div class="contentSty" v-html="articleDetail.content"></div>
                    </div>
                </div>
            </div>
            <div class="sliderSty">
                <side-bar/>
            </div>
        </div>
        <!----------------------------------------------------------------------------------------------------------------->
        <div class="fl_c ju_b contain" style="width: 100%;margin-top: 50px" v-if="$common.mobile()">
            <div class="secondTabBox">
                <div class="topTitle">
                    {{formalItem.name}}
                </div>
            </div>
            <div class="content" style="width: 100%;margin-top: 34px">
                <div v-if="articleDetail" class="pad_around">
                    <div class="fl_c al_c">
                        <div class="contentTitle">
                            {{articleDetail.title}}
                        </div>
                        <div class="contentTime">
                            {{articleDetail.showTime}}
                        </div>
                        <img class="contentImg" v-if="articleDetail.titleImg" :src="articleDetail.titleImg" alt="">
                        <div class="contentSty" v-html="articleDetail.content"></div>
                    </div>
                </div>
            </div>
            <div class="sliderSty" style="width: 100%;height: auto;">
                <side-bar/>
            </div>
        </div>
    </div>
</template>

<script>
    import SideBar from "../../components/SideBar/SideBar";
    import {getarticleDetail, getarticlesInfo} from '../../utils/api/index.js'

    export default {
        name: "detailPage",
        components: {SideBar},
        data() {
            return {
                articleId: '',
                columnId: '',
                articleDetail: {},
            }
        },
        computed: {
            formalItem: {
                get() {
                    return this.$store.state.formalItem
                },
                set(val) {
                },
            },
            childItem: {
                get() {
                    return this.$store.state.childItem
                },
                set(val) {
                },
            },
        },
        watch: {
            // formalItem(newValue, oldValue) {
            //     if (newValue.subArticles.length < 1) {
            //         this.consultList = []
            //     } else {
            //         this.getarticlesInfo(newValue.subArticles[0].id)
            //     }
            // },
            // childItem(newValue, oldValue) {
            //     this.getarticlesInfo(newValue.id)
            // }
        },
        mounted() {
            this.$store.commit('setWeixing', false);
            window.scrollTo(0, 0);
            this.articleId = this.$route.query.articleId
            this.columnId = this.$route.query.columnId
            this.getarticleDetail(this.$route.query.articleId)
            // this.getarticlesInfo(this.$route.query.columnId)
        },
        methods: {
            // async getarticlesInfo(id){
            //   let res = await getarticlesInfo({
            //     columnId:id
            //   })
            //   this.list=res.data.slice(0,5)
            // },
            async getarticleDetail(id) {
                let res = await getarticleDetail({
                    articleId: id
                })
                this.articleDetail = res.data
            }
        },
    }
</script>

<style scoped lang="scss">
    .contain {
        width: 1200px;
        margin-top: 10px;
        height: auto;

        .content {
            width: 930px;
            border: 1px solid #ccc;
            min-height: 800px;

            .contentTitle {
                font-size: 20px;
                font-weight: bold;
            }

            .contentTime {
                width: 100%;
                line-height: 30px;
                height: 30px;
                text-align: center;
                background: #f5f5f5;
                color: #999;
                margin: 20px 0;
            }

            .contentImg {
                width: 100%;
                height: 100%;
            }

            .contentSty {
                margin-top: 20px;
            }
        }
    }

    .sliderSty {
        width: 260px;
    }

    .secondTabBox {
        width: 100%;
        height: auto;
        border: 1px solid #DDDDDD;
        margin-bottom: 10px;
        position: fixed;

    }

    .topTitle {
        width: 100%;
        height: 34px;
        background-color: #195B40;
        font: 600 16px/34px "宋体";
        color: #fff;
        text-align: center;
    }
</style>
